import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import HeroBanner from 'components/heroBanner'
import Intro from 'components/intro'
import CopyBlock from 'components/copyBlock'
import CopySplit from 'components/copySplit'
import ComparisonTable from 'components/comparisonTable'
import PartnerCarousel from 'components/partnerCarousel'
import Awards from 'components/awards'
import { graphql } from 'gatsby'

const VoipPage = ({ data, location }) => (
  <Layout location={location}>
    <Helmet>
      <title>{data.voipJson.title}</title>
      <meta name="description" content={data.voipJson.description} />
    </Helmet>
    <HeroBanner hero={data.voipJson.hero} highlightColor="blue" />
    <Intro intro={data.voipJson.intro} withScrollTo />
    <CopyBlock bgTint noPadding>
      <ComparisonTable tableData={data.voipJson.table} threeColumn />
    </CopyBlock>
    <CopyBlock topMargin id="scroll">
      <CopySplit>
        <h3>VoIP calls are:</h3>
        <div>
          <h4>1. Cheap</h4>
          <p>Calls made over the internet mean significant savings for your business.</p>
          <h4>2. Flexible</h4>
          <p>Multi-functional features fit your business type and your individual needs.</p>
        </div>
      </CopySplit>
      <CopySplit>
        <h3>How we use VoIP</h3>
        <div>
          <p>At PCW Solutions, we use VoIP to help your business to thrive…in the most cost-effective way!</p>
          <p>
            All of our VoIP lines come with unlimited UK landline and mobile minutes. We also offer split pricing so you
            can choose to pay per line and/or per user. Whatever works for you.
          </p>
          <p>
            For instance… <br />
            <i>
              If you’re a small business with 1 user, you could pay for 1 line and 1 user. Then if someone calls your
              business while you’re already on the phone, they’ll hear a busy tone. That extra incoming call can be sent
              to your VoIP voicemail or diverted to another place (if you choose to pay for an extra line).
            </i>
          </p>
          <p>
            If you’re bigger, with 20 employees, we give you the option to buy 10 lines and 10 extra users. Unlike many
            other VoIP providers, you don’t have to pay for 20 lines – because all 20 employees won’t be on the phone at
            the same time! Our more flexible option helps you to cut down costs.
          </p>
          <p>
            Our VoIP service includes the free use of multiple devices. So if you buy 1 line with an extension, you can
            use our mobile app to make and receive calls from your landline number – or use a Click2Call web app on your
            computer, which can ring your physical VoIP desk phone.
          </p>
        </div>
      </CopySplit>
    </CopyBlock>
    <PartnerCarousel />
    <Awards />
  </Layout>
)

VoipPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default VoipPage

export const query = graphql`
  query VoipPageQuery {
    voipJson {
      title
      description
      hero {
        heading
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
      intro {
        heading
        copy
        link
        cta
      }
      table {
        title
        columnHeaders
        records {
          feature
          description
          isInPackage
        }
      }
    }
  }
`
